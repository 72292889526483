import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import * as ds from '../uiLibrary'
import { HeroBackground } from '../components/heroes'
import { ChampionsForm } from '../components'
import { Headline, H3 } from "../uiLibrary/Typographyv2";


const ChampionsApplyPage = ({ data }) => {
  const page = data.allChampionsApplyJson.edges[0].node
  return (
    <Layout>
      <HeroBackground>
        <ds.Background backgroundImage={ds.colors.midnightGradient}>
          <ds.FixedWidthRow>
            <ds.Column span={10}>
              <Headline pt={ds.spacing.s5} fontSize={[ds.typeScale.t6, ds.typeScale.t7, ds.typeScale.t8]} color={ds.brandColors.lightGreen}>{page.heroHeading}</Headline>
              <H3 fontSize={[ds.typeScale.t4, ds.typeScale.t6]} color={ds.brandColors.typography.lightGrey}>{page.heroBody}</H3>
            </ds.Column>
          </ds.FixedWidthRow>
        </ds.Background>
      </HeroBackground>
      <ds.Background bg={"#202020"} py={[ds.spacing.s4, ds.spacing.s5, ds.spacing.s6]}>
        <ds.FixedWidthRow py="0">
          <ds.Column mx={[ds.spacing.s3, ds.spacing.s5]}>
            <ChampionsForm />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const query = graphql`
  query {
    allChampionsApplyJson {
      edges {
        node {
          heroHeading
          heroBody
        }
      }
    }
  }
`

export default ChampionsApplyPage